/*
 * @Author: suk.bear suk.bear.suwu@gmail.com
 * @Date: 2024-01-11 14:48:40
 * @LastEditors: duanshoujian #
 * @LastEditTime: 2024-04-15 17:30:57
 * @FilePath: /dcy-web/apps/admin/constants/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const appName = '达成云管理平台'
export const appDescription = '达成云专业在线教育平台，聚合大量优质教育机构和名师，下设职业培训、公务员考试、托福雅思、考证考级、英语口语、中小学教育等众多在线学习精品课程，打造老师在线上课教学、学生及时互动学习的课堂。达成云，学习成就梦想！'

export const idCardBack = 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/front_of_ID_card.svg'

export const idCardFront = 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/back_of_ID_card.svg'

export enum AuditStatus {
  TeacherWaitingAudit = 1,
  TeacherPassAudit = 2,
  TeacherFailAudit = 3,
  StudentWaitingAudit = 4,
  StudentPassAudit = 5,
  StudentFailAudit = 6,
}

export enum AuditType {
  Automatic = 0,
  User = 1,
}

export const AuditTypeMap = {
  [AuditType.Automatic]: '自动审核',
  [AuditType.User]: '人工审核',
}

export enum RegisterStatus {
  Accept = 1,
  Refuse = 3,
}
