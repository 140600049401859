/*
 * @Author: suk.bear suk.bear.suwu@gmail.com
 * @Date: 2024-01-18 11:27:58
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-05-30 09:52:29
 * @FilePath: \dcy-web2\apps\admin\config\settings.ts
 * @Description: 业务基础信息配置
 */
export default {
  appName: '管理平台',
  theme: 'light',
  colorWeak: false,
  navbar: true,
  menu: true,
  topMenu: false,
  hideMenu: false,
  menuCollapse: false,
  footer: true,
  themeColor: '#3076FF',
  menuWidth: 220,
  globalSettings: false,
  device: 'desktop',
  tabBar: false,
  menuFromServer: false,
  searchBar: true,
  serverMenu: [],
  menuKey: '',
}
