/*
 * @Author: 宋绍华
 * @Date: 2024-08-09 10:28:57
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-08-23 19:16:30
 * @FilePath: \dcy-web\packages\utils\formate\formateMinMax.ts
 * @Description:
 */
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import cn from 'dayjs/locale/zh-cn'

dayjs.locale(cn)

dayjs.extend(minMax)

export const formateMinMax = dayjs
