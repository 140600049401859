/*
 * @Author: 熊志伟
 * @Date: 2023-12-14 08:44:26
 * @LastEditors: 熊志伟
 * @LastEditTime: 2024-03-11 16:45:23
 * @FilePath: \dcy-web\apps\admin\plugins\permission.ts
 * @Description: 权限点自定义指令
 */
// plugins/auth.js

// 移除元素
const removeEl = (el: Element) => el.parentNode && el.parentNode.removeChild(el)

export default defineNuxtPlugin((nuxtApp) => {
  // 不再是 import Vue from 'vue'的写法了
  nuxtApp.vueApp.directive('auth', {
    // 不用mounted，在mounted时用户权限集还是空的
    updated(el, {
      arg, // role | permission
      value, // string | array
    }) {
      if (typeof value == 'string')
        value = [value]

      const userStore = useUserStore()
      const userInfo = arg ? userStore[arg] : []

      let hasAuth = false
      for (const item of value) {
        if (userInfo?.includes(item)) {
          // 用户拥有传入的权限
          hasAuth = true
          break
        }
      }

      if (!hasAuth)
        removeEl(el)
    },
  })
})
