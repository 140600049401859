/*
 * @Author: 熊志伟
 * @Date: 2023-12-11 08:58:16
 * @LastEditors: 熊志伟
 * @LastEditTime: 2024-09-02 16:10:24
 * @FilePath: \dcy-web\apps\admin\stores\index.ts
 * @Description: stores
 */
import { createPinia } from 'pinia'
import useUserStore from './modules/user'
import useAppStore from './modules/app'
import useUserAccountStore from './modules/user/account'
import useBasicStore from './modules/basic'

const pinia = createPinia()

export { useUserStore, useAppStore, useUserAccountStore, useBasicStore }
export default pinia
