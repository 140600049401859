import { default as _91_46_46_46all_93iv2gNiWHUpMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/[...all].vue?macro=true";
import { default as _401QbTXRC2ZpNMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/401.vue?macro=true";
import { default as agreement3wwR8J8U0fMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/agreement.vue?macro=true";
import { default as indexMrUKVw9o65Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/application/index.vue?macro=true";
import { default as indexHM6VOipiFIMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/major-directory/index.vue?macro=true";
import { default as indexcbXJdafmHoMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/menus/index.vue?macro=true";
import { default as indexzkZ6LBqkzgMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/platform/index.vue?macro=true";
import { default as indexA93WUIucjjMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/tag/index.vue?macro=true";
import { default as appmanagep8ysKHlYYKMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage.vue?macro=true";
import { default as AddModal6zj33zwgh3Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/grade-and-class/compontents/AddModal.vue?macro=true";
import { default as indexX4xlbmr95IMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/grade-and-class/index.vue?macro=true";
import { default as indexgKOh7FSaU5Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/professional-management/index.vue?macro=true";
import { default as indexDJpQkRmhXzMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/course-preview/index.vue?macro=true";
import { default as indexZW0o3E67dLMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/device-management/index.vue?macro=true";
import { default as indexJNq2yZpQSLMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/lab-management/index.vue?macro=true";
import { default as indexqlDNzoyiFvMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/product-management/index.vue?macro=true";
import { default as indexmwtiSf6FfLMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/scene-management/index.vue?macro=true";
import { default as indexgzfnfUWfhfMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/forgot-password/index.vue?macro=true";
import { default as indexdoEudkPgJ0Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/index.vue?macro=true";
import { default as indexag7xR6qgQTMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/lesson-library-detail-preview/index.vue?macro=true";
import { default as indexl9HeCdkBHzMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/login/index.vue?macro=true";
import { default as indexBgaxn2Ax9sMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/course-data/index.vue?macro=true";
import { default as indexRRmQZWYNukMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/exercise-data/index.vue?macro=true";
import { default as indexC35VnOCgVlMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/index.vue?macro=true";
import { default as indexgnmOFORWtxMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/organization/index.vue?macro=true";
import { default as indexeyHudLQO9yMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/personal/index.vue?macro=true";
import { default as indexN2LyvMUqCnMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-course/index.vue?macro=true";
import { default as indexCFCtl9j8SkMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-exercise/index.vue?macro=true";
import { default as indexu09jc4QxE0Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-user-management/index.vue?macro=true";
import { default as ReceiveResourcesModallJmfDqqdY7Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/compontents/ReceiveResourcesModal.vue?macro=true";
import { default as indexBoL1AQVoOlMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/index.vue?macro=true";
import { default as indexv1fpMbZor2Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-management-list/index.vue?macro=true";
import { default as EditDrawerModal4cA5Z19vX5Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/EditDrawerModal.vue?macro=true";
import { default as PublishModalYs6BIGIFBmMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/PublishModal.vue?macro=true";
import { default as indexR7XxLfSxVwMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/index.vue?macro=true";
import { default as index2uK5kuz6SrMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-resources/detail/index.vue?macro=true";
import { default as indexvduBULusbtMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/register/index.vue?macro=true";
import { default as indexbcDpReBVBRMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/course-management/index.vue?macro=true";
import { default as indexWfXU8HYtO3Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/exercise-management/index.vue?macro=true";
import { default as indexbtBGzBOlocMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/task-library-detail-preview/index.vue?macro=true";
import { default as indexdIB29Kf55sMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/operate-user-management/index.vue?macro=true";
import { default as indexEudCnD6VdMMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/role-management/index.vue?macro=true";
import { default as indexpHrCUqK3tNMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/student-management/index.vue?macro=true";
import { default as indexH1pCGtOySaMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/teacher-management/index.vue?macro=true";
import { default as indexYTo1JckeZ4Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/verb/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93iv2gNiWHUpMeta?.name ?? "all",
    path: _91_46_46_46all_93iv2gNiWHUpMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93iv2gNiWHUpMeta || {},
    alias: _91_46_46_46all_93iv2gNiWHUpMeta?.alias || [],
    redirect: _91_46_46_46all_93iv2gNiWHUpMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _401QbTXRC2ZpNMeta?.name ?? "401",
    path: _401QbTXRC2ZpNMeta?.path ?? "/401",
    meta: _401QbTXRC2ZpNMeta || {},
    alias: _401QbTXRC2ZpNMeta?.alias || [],
    redirect: _401QbTXRC2ZpNMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/401.vue").then(m => m.default || m)
  },
  {
    name: agreement3wwR8J8U0fMeta?.name ?? "agreement",
    path: agreement3wwR8J8U0fMeta?.path ?? "/agreement",
    meta: agreement3wwR8J8U0fMeta || {},
    alias: agreement3wwR8J8U0fMeta?.alias || [],
    redirect: agreement3wwR8J8U0fMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/agreement.vue").then(m => m.default || m)
  },
  {
    name: appmanagep8ysKHlYYKMeta?.name ?? "appmanage",
    path: appmanagep8ysKHlYYKMeta?.path ?? "/appmanage",
    children: [
  {
    name: indexMrUKVw9o65Meta?.name ?? "appmanage-application",
    path: indexMrUKVw9o65Meta?.path ?? "application",
    meta: indexMrUKVw9o65Meta || {},
    alias: indexMrUKVw9o65Meta?.alias || [],
    redirect: indexMrUKVw9o65Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/application/index.vue").then(m => m.default || m)
  },
  {
    name: indexHM6VOipiFIMeta?.name ?? "appmanage-major-directory",
    path: indexHM6VOipiFIMeta?.path ?? "major-directory",
    meta: indexHM6VOipiFIMeta || {},
    alias: indexHM6VOipiFIMeta?.alias || [],
    redirect: indexHM6VOipiFIMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/major-directory/index.vue").then(m => m.default || m)
  },
  {
    name: indexcbXJdafmHoMeta?.name ?? "appmanage-menus",
    path: indexcbXJdafmHoMeta?.path ?? "menus",
    meta: indexcbXJdafmHoMeta || {},
    alias: indexcbXJdafmHoMeta?.alias || [],
    redirect: indexcbXJdafmHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/menus/index.vue").then(m => m.default || m)
  },
  {
    name: indexzkZ6LBqkzgMeta?.name ?? "appmanage-platform",
    path: indexzkZ6LBqkzgMeta?.path ?? "platform",
    meta: indexzkZ6LBqkzgMeta || {},
    alias: indexzkZ6LBqkzgMeta?.alias || [],
    redirect: indexzkZ6LBqkzgMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/platform/index.vue").then(m => m.default || m)
  },
  {
    name: indexA93WUIucjjMeta?.name ?? "appmanage-tag",
    path: indexA93WUIucjjMeta?.path ?? "tag",
    meta: indexA93WUIucjjMeta || {},
    alias: indexA93WUIucjjMeta?.alias || [],
    redirect: indexA93WUIucjjMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/tag/index.vue").then(m => m.default || m)
  }
],
    meta: appmanagep8ysKHlYYKMeta || {},
    alias: appmanagep8ysKHlYYKMeta?.alias || [],
    redirect: appmanagep8ysKHlYYKMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage.vue").then(m => m.default || m)
  },
  {
    name: AddModal6zj33zwgh3Meta?.name ?? "basic-data-management-grade-and-class-compontents-AddModal",
    path: AddModal6zj33zwgh3Meta?.path ?? "/basic-data-management/grade-and-class/compontents/AddModal",
    meta: AddModal6zj33zwgh3Meta || {},
    alias: AddModal6zj33zwgh3Meta?.alias || [],
    redirect: AddModal6zj33zwgh3Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/grade-and-class/compontents/AddModal.vue").then(m => m.default || m)
  },
  {
    name: indexX4xlbmr95IMeta?.name ?? "basic-data-management-grade-and-class",
    path: indexX4xlbmr95IMeta?.path ?? "/basic-data-management/grade-and-class",
    meta: indexX4xlbmr95IMeta || {},
    alias: indexX4xlbmr95IMeta?.alias || [],
    redirect: indexX4xlbmr95IMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/grade-and-class/index.vue").then(m => m.default || m)
  },
  {
    name: indexgKOh7FSaU5Meta?.name ?? "basic-data-management-professional-management",
    path: indexgKOh7FSaU5Meta?.path ?? "/basic-data-management/professional-management",
    meta: indexgKOh7FSaU5Meta || {},
    alias: indexgKOh7FSaU5Meta?.alias || [],
    redirect: indexgKOh7FSaU5Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/professional-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexDJpQkRmhXzMeta?.name ?? "course-preview",
    path: indexDJpQkRmhXzMeta?.path ?? "/course-preview",
    meta: indexDJpQkRmhXzMeta || {},
    alias: indexDJpQkRmhXzMeta?.alias || [],
    redirect: indexDJpQkRmhXzMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/course-preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexZW0o3E67dLMeta?.name ?? "equipment-center-device-management",
    path: indexZW0o3E67dLMeta?.path ?? "/equipment-center/device-management",
    meta: indexZW0o3E67dLMeta || {},
    alias: indexZW0o3E67dLMeta?.alias || [],
    redirect: indexZW0o3E67dLMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/device-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexJNq2yZpQSLMeta?.name ?? "equipment-center-lab-management",
    path: indexJNq2yZpQSLMeta?.path ?? "/equipment-center/lab-management",
    meta: indexJNq2yZpQSLMeta || {},
    alias: indexJNq2yZpQSLMeta?.alias || [],
    redirect: indexJNq2yZpQSLMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/lab-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexqlDNzoyiFvMeta?.name ?? "equipment-center-product-management",
    path: indexqlDNzoyiFvMeta?.path ?? "/equipment-center/product-management",
    meta: indexqlDNzoyiFvMeta || {},
    alias: indexqlDNzoyiFvMeta?.alias || [],
    redirect: indexqlDNzoyiFvMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/product-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexmwtiSf6FfLMeta?.name ?? "equipment-center-scene-management",
    path: indexmwtiSf6FfLMeta?.path ?? "/equipment-center/scene-management",
    meta: indexmwtiSf6FfLMeta || {},
    alias: indexmwtiSf6FfLMeta?.alias || [],
    redirect: indexmwtiSf6FfLMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/scene-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexgzfnfUWfhfMeta?.name ?? "forgot-password",
    path: indexgzfnfUWfhfMeta?.path ?? "/forgot-password",
    meta: indexgzfnfUWfhfMeta || {},
    alias: indexgzfnfUWfhfMeta?.alias || [],
    redirect: indexgzfnfUWfhfMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoEudkPgJ0Meta?.name ?? "index",
    path: indexdoEudkPgJ0Meta?.path ?? "/",
    meta: indexdoEudkPgJ0Meta || {},
    alias: indexdoEudkPgJ0Meta?.alias || [],
    redirect: indexdoEudkPgJ0Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexag7xR6qgQTMeta?.name ?? "lesson-library-detail-preview",
    path: indexag7xR6qgQTMeta?.path ?? "/lesson-library-detail-preview",
    meta: indexag7xR6qgQTMeta || {},
    alias: indexag7xR6qgQTMeta?.alias || [],
    redirect: indexag7xR6qgQTMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/lesson-library-detail-preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexl9HeCdkBHzMeta?.name ?? "login",
    path: indexl9HeCdkBHzMeta?.path ?? "/login",
    meta: indexl9HeCdkBHzMeta || {},
    alias: indexl9HeCdkBHzMeta?.alias || [],
    redirect: indexl9HeCdkBHzMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexBgaxn2Ax9sMeta?.name ?? "org-resource-management-course-data",
    path: indexBgaxn2Ax9sMeta?.path ?? "/org-resource-management/course-data",
    meta: indexBgaxn2Ax9sMeta || {},
    alias: indexBgaxn2Ax9sMeta?.alias || [],
    redirect: indexBgaxn2Ax9sMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/course-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexRRmQZWYNukMeta?.name ?? "org-resource-management-exercise-data",
    path: indexRRmQZWYNukMeta?.path ?? "/org-resource-management/exercise-data",
    meta: indexRRmQZWYNukMeta || {},
    alias: indexRRmQZWYNukMeta?.alias || [],
    redirect: indexRRmQZWYNukMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/exercise-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexC35VnOCgVlMeta?.name ?? "organization-management",
    path: indexC35VnOCgVlMeta?.path ?? "/organization-management",
    meta: indexC35VnOCgVlMeta || {},
    alias: indexC35VnOCgVlMeta?.alias || [],
    redirect: indexC35VnOCgVlMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexgnmOFORWtxMeta?.name ?? "organization-management-organization",
    path: indexgnmOFORWtxMeta?.path ?? "/organization-management/organization",
    meta: indexgnmOFORWtxMeta || {},
    alias: indexgnmOFORWtxMeta?.alias || [],
    redirect: indexgnmOFORWtxMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/organization/index.vue").then(m => m.default || m)
  },
  {
    name: indexeyHudLQO9yMeta?.name ?? "personal",
    path: indexeyHudLQO9yMeta?.path ?? "/personal",
    meta: indexeyHudLQO9yMeta || {},
    alias: indexeyHudLQO9yMeta?.alias || [],
    redirect: indexeyHudLQO9yMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/personal/index.vue").then(m => m.default || m)
  },
  {
    name: indexN2LyvMUqCnMeta?.name ?? "platform-resource-management-quality-course",
    path: indexN2LyvMUqCnMeta?.path ?? "/platform-resource-management/quality-course",
    meta: indexN2LyvMUqCnMeta || {},
    alias: indexN2LyvMUqCnMeta?.alias || [],
    redirect: indexN2LyvMUqCnMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-course/index.vue").then(m => m.default || m)
  },
  {
    name: indexCFCtl9j8SkMeta?.name ?? "platform-resource-management-quality-exercise",
    path: indexCFCtl9j8SkMeta?.path ?? "/platform-resource-management/quality-exercise",
    meta: indexCFCtl9j8SkMeta || {},
    alias: indexCFCtl9j8SkMeta?.alias || [],
    redirect: indexCFCtl9j8SkMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-exercise/index.vue").then(m => m.default || m)
  },
  {
    name: indexu09jc4QxE0Meta?.name ?? "platform-user-management",
    path: indexu09jc4QxE0Meta?.path ?? "/platform-user-management",
    meta: indexu09jc4QxE0Meta || {},
    alias: indexu09jc4QxE0Meta?.alias || [],
    redirect: indexu09jc4QxE0Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-user-management/index.vue").then(m => m.default || m)
  },
  {
    name: ReceiveResourcesModallJmfDqqdY7Meta?.name ?? "project-project-data-compontents-ReceiveResourcesModal",
    path: ReceiveResourcesModallJmfDqqdY7Meta?.path ?? "/project/project-data/compontents/ReceiveResourcesModal",
    meta: ReceiveResourcesModallJmfDqqdY7Meta || {},
    alias: ReceiveResourcesModallJmfDqqdY7Meta?.alias || [],
    redirect: ReceiveResourcesModallJmfDqqdY7Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/compontents/ReceiveResourcesModal.vue").then(m => m.default || m)
  },
  {
    name: indexBoL1AQVoOlMeta?.name ?? "project-project-data",
    path: indexBoL1AQVoOlMeta?.path ?? "/project/project-data",
    meta: indexBoL1AQVoOlMeta || {},
    alias: indexBoL1AQVoOlMeta?.alias || [],
    redirect: indexBoL1AQVoOlMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexv1fpMbZor2Meta?.name ?? "project-project-management-list",
    path: indexv1fpMbZor2Meta?.path ?? "/project/project-management-list",
    meta: indexv1fpMbZor2Meta || {},
    alias: indexv1fpMbZor2Meta?.alias || [],
    redirect: indexv1fpMbZor2Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-management-list/index.vue").then(m => m.default || m)
  },
  {
    name: EditDrawerModal4cA5Z19vX5Meta?.name ?? "project-project-recommended-compontents-EditDrawerModal",
    path: EditDrawerModal4cA5Z19vX5Meta?.path ?? "/project/project-recommended/compontents/EditDrawerModal",
    meta: EditDrawerModal4cA5Z19vX5Meta || {},
    alias: EditDrawerModal4cA5Z19vX5Meta?.alias || [],
    redirect: EditDrawerModal4cA5Z19vX5Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/EditDrawerModal.vue").then(m => m.default || m)
  },
  {
    name: PublishModalYs6BIGIFBmMeta?.name ?? "project-project-recommended-compontents-PublishModal",
    path: PublishModalYs6BIGIFBmMeta?.path ?? "/project/project-recommended/compontents/PublishModal",
    meta: PublishModalYs6BIGIFBmMeta || {},
    alias: PublishModalYs6BIGIFBmMeta?.alias || [],
    redirect: PublishModalYs6BIGIFBmMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/PublishModal.vue").then(m => m.default || m)
  },
  {
    name: indexR7XxLfSxVwMeta?.name ?? "project-project-recommended",
    path: indexR7XxLfSxVwMeta?.path ?? "/project/project-recommended",
    meta: indexR7XxLfSxVwMeta || {},
    alias: indexR7XxLfSxVwMeta?.alias || [],
    redirect: indexR7XxLfSxVwMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/index.vue").then(m => m.default || m)
  },
  {
    name: index2uK5kuz6SrMeta?.name ?? "project-project-resources-detail",
    path: index2uK5kuz6SrMeta?.path ?? "/project/project-resources/detail",
    meta: index2uK5kuz6SrMeta || {},
    alias: index2uK5kuz6SrMeta?.alias || [],
    redirect: index2uK5kuz6SrMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-resources/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexvduBULusbtMeta?.name ?? "register",
    path: indexvduBULusbtMeta?.path ?? "/register",
    meta: indexvduBULusbtMeta || {},
    alias: indexvduBULusbtMeta?.alias || [],
    redirect: indexvduBULusbtMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexbcDpReBVBRMeta?.name ?? "school-based-resource-management-course-management",
    path: indexbcDpReBVBRMeta?.path ?? "/school-based-resource-management/course-management",
    meta: indexbcDpReBVBRMeta || {},
    alias: indexbcDpReBVBRMeta?.alias || [],
    redirect: indexbcDpReBVBRMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/course-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexWfXU8HYtO3Meta?.name ?? "school-based-resource-management-exercise-management",
    path: indexWfXU8HYtO3Meta?.path ?? "/school-based-resource-management/exercise-management",
    meta: indexWfXU8HYtO3Meta || {},
    alias: indexWfXU8HYtO3Meta?.alias || [],
    redirect: indexWfXU8HYtO3Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/exercise-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexbtBGzBOlocMeta?.name ?? "task-library-detail-preview",
    path: indexbtBGzBOlocMeta?.path ?? "/task-library-detail-preview",
    meta: indexbtBGzBOlocMeta || {},
    alias: indexbtBGzBOlocMeta?.alias || [],
    redirect: indexbtBGzBOlocMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/task-library-detail-preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexdIB29Kf55sMeta?.name ?? "user-management-operate-user-management",
    path: indexdIB29Kf55sMeta?.path ?? "/user-management/operate-user-management",
    meta: indexdIB29Kf55sMeta || {},
    alias: indexdIB29Kf55sMeta?.alias || [],
    redirect: indexdIB29Kf55sMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/operate-user-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexEudCnD6VdMMeta?.name ?? "user-management-role-management",
    path: indexEudCnD6VdMMeta?.path ?? "/user-management/role-management",
    meta: indexEudCnD6VdMMeta || {},
    alias: indexEudCnD6VdMMeta?.alias || [],
    redirect: indexEudCnD6VdMMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/role-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexpHrCUqK3tNMeta?.name ?? "user-management-student-management",
    path: indexpHrCUqK3tNMeta?.path ?? "/user-management/student-management",
    meta: indexpHrCUqK3tNMeta || {},
    alias: indexpHrCUqK3tNMeta?.alias || [],
    redirect: indexpHrCUqK3tNMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/student-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexH1pCGtOySaMeta?.name ?? "user-management-teacher-management",
    path: indexH1pCGtOySaMeta?.path ?? "/user-management/teacher-management",
    meta: indexH1pCGtOySaMeta || {},
    alias: indexH1pCGtOySaMeta?.alias || [],
    redirect: indexH1pCGtOySaMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/teacher-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexYTo1JckeZ4Meta?.name ?? "verb",
    path: indexYTo1JckeZ4Meta?.path ?? "/verb",
    meta: indexYTo1JckeZ4Meta || {},
    alias: indexYTo1JckeZ4Meta?.alias || [],
    redirect: indexYTo1JckeZ4Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/verb/index.vue").then(m => m.default || m)
  }
]