<!--
 * @Author: 熊志伟
 * @Date: 2024-01-08 15:02:22
 * @LastEditors: huang liang miao 1127255342@qq.com
 * @LastEditTime: 2024-08-20 14:57:51
 * @FilePath: \dcy-web2\apps\admin\app.vue
 * @Description: 初始
-->
<script setup lang="ts">
import { appName } from '~/constants'
import '@arco-design/web-vue/es/message/style/css.js'

useHead({
  title: appName,
})
const route = useRoute()
const whiteList = ['/login', '/register', '/forgot-password']
onMounted(async () => {
  const path = route.path
  if (whiteList.includes(path))
    return
  const userStore = useUserStore()
  await userStore.info()
})
</script>

<template>
  <!-- <VitePwaManifest /> -->
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
html, body , #__nuxt{
  min-width: 1440px!important;
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  background: #F7F8FA;
  color: #1D2129;
  overflow-x: auto;
  font-family: PingFang SC,Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,Microsoft YaHei;
  font-style: normal;
}

html.dark {
  background: #F7F8FA;
  color: #1D2129;
}

/* 滚动槽 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.layout-content{
  --at-apply: p-4 bg-white h-full;
}

::selection {
  --at-apply:  bg-#D5E1FC;
}
</style>

<style lang="less">
.dcy-default-layout-wrapper {
  .arco-menu-item,.arco-menu-pop   {
    --at-apply: flex justify-center;
  }
  .arco-menu-vertical .arco-menu-pop-header {
    padding-right: 12px !important;
  }
  .arco-menu-icon {
    margin-right: -4px !important;
  }
  .arco-menu-title {
    width: 0 !important;
  }
}
body{

  .arco-checkbox-disabled.arco-checkbox-checked .arco-checkbox-label,
  .arco-checkbox-disabled.arco-checkbox-checked:hover .arco-checkbox-label{
    --at-apply: text-[#86909C];
  }
  .arco-checkbox-disabled.arco-checkbox-checked .arco-checkbox-icon,
  .arco-checkbox-disabled.arco-checkbox-checked:hover .arco-checkbox-icon,
  .arco-radio-checked .arco-radio-icon,
  .arco-checkbox-indeterminate .arco-checkbox-icon,
  .arco-checkbox-checked .arco-checkbox-icon {
    --at-apply: bg-[#6B6AFF] border-color-[#6B6AFF];
  }
  .arco-tree-node-selected .arco-tree-node-title,
  .arco-tree-node-selected .arco-tree-node-title:hover{
    --at-apply: text-[#6B6AFF];
  }
  .arco-menu-dark .arco-menu-inline-header.arco-menu-selected{
    --at-apply: text-white;
  }
  .arco-select-view,
  .arco-select-view:hover,
  .arco-picker,
  .arco-picker:hover,
  .arco-input-wrapper,
  .arco-input-wrapper:hover{
    --at-apply: bg-white border border-gray-300;
  }
  body{
    // 长单词换行
    word-break: break-all;
  }

  // 覆盖组件默认样式
  .arco-modal-header {
    border-bottom: 1px solid #E5E6EB;
  }

  .arco-modal-title {
    justify-content: start !important;
    font-weight: 500;
    font-size: 14px;
    color: #4E5969;
  }

  .arco-modal-close-btn {
    font-size: 16px;
    color: #4E5969;
  }

  .arco-link {
    color: #6B6AFF;
  }

  .arco-link:hover {
    color: #6B6AFF;
  }

  .arco-link-status-danger {
    color: #F53F3F;
  }

  .arco-link-status-danger:hover {
    color: #F53F3F;
  }

  .arco-table-th {
    background-color: #F2F3F5;
    border-color: #E5E6EB;
  }

  .arco-table-td {
    border-color: #E5E6EB;
  }

  .arco-table-cell {
    padding: 14px 16px !important;
  }

  .cell-none-1765894875645 {
    position: relative;

    .arco-table-cell {
      padding: 0 !important;
    }

    .cell-none-inner {
      padding: 14px 16px !important;
      display: flex;
      align-items: center;
    }
  }

  .cell-none-1765894875645 {
    .arco-table-cell {
      padding: 0 !important;
    }

    .cell-none-inner {
      padding: 14px 16px !important;
      display: flex;
      align-items: center;
    }
  }

  .arco-tabs-nav-type-line .arco-tabs-tab {
    padding: 14px 0px !important;
    line-height: 48px;
    height: 48px;
  }

  .arco-tabs-tab-active {
    --at-apply: font-500 color-[#6B6AFF];
  }

  .arco-tabs-nav-ink {
    --at-apply: bg-[#6B6AFF];
  }

  .arco-tabs-nav-type-line .arco-tabs-tab:hover .arco-tabs-tab-title::before {
    background-color: transparent !important;
  }

  .arco-btn-secondary.arco-btn-status-warning {
    background-color: #FF7B50;
    color: #fff;
  }

  .arco-select-view-single:focus-within,
  .arco-select-view-single.arco-select-view-focus {
    border-color: #6B6AFF;
  }

  .arco-input-wrapper:focus-within,
  .arco-input-wrapper.arco-input-focus {
    border-color: #6B6AFF;
  }

  .arco-pagination-item-active,
  .arco-pagination-item-active:hover {
    color: #6B6AFF;
    background-color: #F3F3FF;
  }

  .appName {
    color: #1D2129;
    font-weight: bold;
    padding-right: 32px;
    padding-left: 30px;
    display: inline-block;
    font-size: 16px;

  }

  .menu-list {
    position: relative;
    padding-left: 30px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 16px;
      width: 1px;
      transform: translateY(-50%);
      background-color: #F2F3F5;
    }
  }

  // 覆盖组件默认样式
  .arco-modal-header {
    border-bottom: 1px solid #E5E6EB;
  }

  .arco-modal-title {
    justify-content: start !important;
    font-weight: 500;
    font-size: 14px;
    color: #4E5969;
  }

  .arco-modal-close-btn {
    font-size: 16px;
    color: #4E5969;
  }

  .arco-breadcrumb-item a:hover {
    color: #1D2129 !important;
  }

  .arco-btn-primary {
    background-color: #6B6AFF !important;
  }

  .arco-btn-primary:hover {
    background-color: #8887FF !important;
  }

  .arco-btn-primary:active {
    background-color: #4E4DFF !important;
  }

  .arco-btn-outline {
    color: #6B6AFF !important;
    border-color: #6B6AFF !important;
  }

  .arco-btn-outline:hover {
    color: #8887FF !important;
    border-color: #8887FF !important;
  }

  .arco-btn-outline:active {
    color: #4E4DFF !important;
    border-color: #4E4DFF !important;
  }

  .arco-btn-status-danger {
    color: #F53F3F !important;
    border-color: #F53F3F !important;
  }

  .arco-btn-status-danger:hover {
    color: #F76560 !important;
    border-color: #F76560 !important;
  }

  .arco-btn-status-danger:active {
    color: #CB272D !important;
    border-color: #CB272D !important;
  }

  .arco-btn-text:hover {
    color: #8887FF !important;
    background-color: transparent !important;
  }

  .arco-btn-text:active {
    color: #4E4DFF !important;
    background-color: transparent !important;
  }

  .arco-btn-text {
    color: #6B6AFF !important;

    &.arco-btn-status-danger {
      color: #F53F3F !important;
      border-color: transparent !important;
    }

    &.arco-btn-status-danger:active {
      color: #F53F3F !important;
      background-color: transparent !important;
    }
  }

  .arco-table-th {
    background-color: #F2F3F5;
  }

  .arco-table-cell {
    padding: 14px 16px !important;
  }

  .arco-tabs-nav-type-line .arco-tabs-tab {
    padding: 14px 0px !important;
    line-height: 48px;
    height: 48px;
  }

  .arco-tabs-nav-type-line .arco-tabs-tab:hover .arco-tabs-tab-title::before {
    background-color: transparent !important;
  }

  .arco-btn-secondary.arco-btn-status-warning {
    background-color: #FF7B50;
    color: #fff;
  }
  .appName {
    color: #1D2129;
    font-weight: bold;
    padding-right: 32px;
    padding-left: 30px;
    display: inline-block;
    font-size: 16px;

  }
  .menu-list {
    position: relative;
    padding-left: 30px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 16px;
      width: 1px;
      transform: translateY(-50%);
      background-color: #F2F3F5;
    }
  }
}
</style>
