import zhCn from 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import duration from 'dayjs/plugin/duration'

dayjs.locale(zhCn)
dayjs.extend(IsSameOrBefore)
dayjs.extend(duration)
export const formateDate = dayjs

/**
 * 格式化超过24小时的时长
 * @param duration 时间差的时间戳
 * @returns {string} 格式化后的数据 xx小时xx分xx秒
 */
export function formatDuration(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  // 超过72小时以72小时展示
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 72)

  return `${hours.toString().padStart(2, '0')}小时${minutes.toString().padStart(2, '0')}分${seconds.toString().padStart(2, '0')}秒`
}
