export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width"},{"name":"description","content":"达成云专业在线教育平台，聚合大量优质教育机构和名师，下设职业培训、公务员考试、托福雅思、考证考级、英语口语、中小学教育等众多在线学习精品课程，打造老师在线上课教学、学生及时互动学习的课堂。达成云，学习成就梦想！"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","type":"image/svg+xml","href":"/nuxt.svg"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"stylesheet","href":"https://dcy-static.oss-cn-hangzhou.aliyuncs.com/files/iconfont/iconfont.css"},{"rel":"stylesheet","href":"https://cdn2.codesign.qq.com/icons/EpZGKy4NzBYBaZw/latest/iconfont.css"}],"style":[],"script":[{"src":"https://formula.metaobe.com/pluginwiris_engine/app/WIRISplugins.js?viewer=image"},{"src":"https://dcy-static.oss-cn-hangzhou.aliyuncs.com/files/base64.min.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'