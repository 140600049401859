import isTomorrow from 'dayjs/plugin/isTomorrow'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import dayjs from 'dayjs'

dayjs.extend(isTomorrow)
dayjs.extend(isToday)
dayjs.extend(isYesterday)

export const formateIsDay = dayjs
