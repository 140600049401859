/*
 * @Author: 熊志伟
 * @Date: 2023-12-08 17:09:59
 * @LastEditors: 熊志伟
 * @LastEditTime: 2023-12-28 14:59:51
 * @FilePath: \teaching-spaced:\work\dcy-web\packages\utils\auth.ts
 * @Description: do
 */
import Cookies from 'js-cookie'

const TOKEN_KEY = 'token'

export function isLogin() {
  return !!localStorage.getItem(TOKEN_KEY)
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token)
}

export function clearToken() {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('menuInfo')
}

export function setRemember(remember: boolean) {
  localStorage.setItem('remember', remember ? '1' : '0')
}

export function clearRemember() {
  localStorage.removeItem('remember')
}

export function setUserAccount(username: string, password: string) {
  localStorage.setItem('username', username)
  localStorage.setItem('password', password)
}

export function clearUserAccount() {
  localStorage.removeItem('username')
  localStorage.removeItem('password')
}

// 清除所有cookie
export function clearAllCookie() {
  const keys = Object.keys(Cookies.get())
  if (keys?.length)
    keys.forEach(item => Cookies.remove(item))
}

// 清除所有缓存数据
export function clearAllCache() {
  clearAllCookie()
  localStorage.clear()
  sessionStorage.clear()
}
