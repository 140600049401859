/*
 * @Author: 宋绍华
 * @Date: 2024-01-02 17:25:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-10-21 15:06:59
 * @FilePath: \dcy-web\packages\utils\constText.ts
 * @Description: 公用字典
 */

import { BookingStatus, ExamineProjectList, ExerciseDifficulty, ExerciseStatus, ProjectPhaseEvaluateStatus, ProjectStatusEnum, ProjectTaskDynamicStatus, SummaryMetricsEnum, examTypeEnum, fileDirectoryTypeEnum } from './constEnum'

// 课程：ILO 程度
export const CourseILOLevelMap = {
  L1: 'L1：记忆',
  L2: 'L2：理解',
  L3: 'L3：运用',
  L4: 'L4：分析',
  L5: 'L5：综合',
  L6: 'L6：评价',
}

// 英文列表
export const engTextList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'] as const

export type EngTextListType = typeof engTextList[number]

export const difficultTypeList = [
  {
    val: '0.9',
    label: '容易',
    value: 1,
  },
  {
    val: '0.7',
    label: '较易',
    value: 2,
  },
  {
    val: '0.5',
    label: '适中',
    value: 3,
  },
  {
    val: '0.3',
    label: '较难',
    value: 4,
  },
  {
    val: '0.1',
    label: '困难',
    value: 5,
  },
]

// 难易程度Map
export const difficultyTextMap = {
  1: {
    val: '0.9',
    label: '容易',
    value: 1,
  },
  2: {
    val: '0.7',
    label: '较易',
    value: 2,
  },
  3: {
    val: '0.5',
    label: '适中',
    value: 3,
  },
  4: {
    val: '0.3',
    label: '较难',
    value: 4,
  },
  5: {
    val: '0.1',
    label: '困难',
    value: 5,
  },
} as any
// 难易程度数组
export const difficultyArray = Object.entries(difficultyTextMap).map(([key, value]) => {
  return {
    id: Number.parseInt(key, 10),
    val: value.val,
    label: value.label,
    value: value.value,
  }
})

export const exerciseTypeMap = {
  1: '判断题',
  2: '单选题',
  3: '多选题',
  4: '填空题',
  5: '计算题',
  6: '简答题',
  7: '论述题',
}

export const exerciseTypeList = [
  {
    label: '判断题',
    value: 1,
  },
  {
    label: '单选题',
    value: 2,
  },
  {
    label: '多选题',
    value: 3,
  },
  {
    label: '填空题',
    value: 4,
  },
  {
    label: '计算题',
    value: 5,
  },
  {
    label: '简答题',
    value: 6,
  },
  {
    label: '论述题',
    value: 7,
  },
]

// 习题类型;1-判断题,2-单选题,3-多选题,4-填空题,5-计算题
export const questionTypeMap = {
  1: {
    label: '单选题',
    val: 2,
  },
  2: {
    label: '多选题',
    val: 3,
  },
  3: {
    label: '判断题',
    val: 1,
  },
  4: {
    label: '填空题',
    val: 4,
  },
  5: {
    label: '计算题',
    val: 5,
  },
  6: {
    label: '简答题',
    val: 6,
  },
  7: {
    label: '论述题',
    val: 7,
  },
}

// 习题类型 数组
export const questionTypeArray = Object.entries(questionTypeMap).map(([key, value]) => {
  return {
    id: Number.parseInt(key, 10),
    val: value.val,
    label: value.label,
    key,
  }
})

// 缓存key名称
export const storageConstKey = {
  'RESOURCE-LIBRARY-PROFESS-KEYS': 'resource-library-profess-keys', // 资源空间缓存当前习题库专业id
  'ACHIEVE-SUB-PROJECT-PHASE': 'achieve-sub-project-phase', //  项目空间达成子项目id 阶段id
}

// 课程栏相关列表
export const courseColumnList = [
  {
    id: 0,
    label: '课程首页',
    url: '/my-course/details/home',
  },
  {
    id: 1,
    label: '课程详情',
    url: '/my-course/schedule',
  },
  {
    id: 2,
    label: '课程大纲',
    url: '/my-course/details/outline',
  },
  {
    id: 3,
    label: '课程任务',
    url: '/my-course/tasks',
  },
  {
    id: 4,
    label: '课程班级',
    url: '/my-course/course-class',
  },
  {
    id: 5,
    label: '课程达成',
    url: '/my-course/achieve',
  },
  {
    id: 6,
    label: '学习分析',
    url: '/my-course/analyze',
  },
  {
    id: 7,
    label: '课程收藏',
    url: '/my-course/collect',
  },
]
// 项目栏栏相关列表
export const projectColumnList = [
  {
    id: 0,
    label: '项目概况',
    url: '/project/overview',
  },
  {
    id: 1,
    label: '小组成员',
    url: '/project/group',
  },
  {
    id: 2,
    label: '项目小组',
    url: '/project/tasks',
  },
  // {
  //   id: 3,
  //   label: '项目资料',
  //   url: '/project/info-list',
  // },
  {
    id: 4,
    label: '项目细则',
    url: '/project/detail-rules',
  },
  {
    id: 5,
    label: '项目评价',
    url: '/project/evaluate-list',
  },
  {
    id: 6,
    label: '项目达成',
    url: '/project/completion/achieve',
    linkOuter: true,
  },
  {
    id: 7,
    label: '项目任务',
    url: '/project/phase-task/task-details',
  },
  {
    id: 8,
    label: '教学计划',
    url: '/project/teaching-plan',
  },
]
// 项目状态Map
export const projectTaskStatus = {
  0: '待指派',
  1: '待开始',
  2: '进行中',
  3: '待审核',
  4: '待结束',
  10: '已逾期',
  99: '已结束',
}

// 项目阶段状态
export enum ProjectPhaseEnum {
  unStart = 0, //  0-未开始
  doing = 1, //  1-进行中
  done = 2, //  2-已完成
  overdue = 3, //  逾期
}

// 项目阶段状态
export const projectPhaseStatus = {
  [ProjectPhaseEnum.unStart]: '未开始',
  [ProjectPhaseEnum.doing]: '进行中',
  [ProjectPhaseEnum.done]: '已完成',
  [ProjectPhaseEnum.overdue]: '已逾期',
}

// 项目状态
export const projectPhaseColor = {
  [ProjectPhaseEnum.unStart]: '#C9CDD4',
  [ProjectPhaseEnum.doing]: '#6B6AFF',
  [ProjectPhaseEnum.done]: '#00B42A',
  [ProjectPhaseEnum.overdue]: '#F53F3F',
}

// 项目阶段评价状态
export const ProjectPhaseEvaluateStatusText = {
  [ProjectPhaseEvaluateStatus.unStart]: '未开始',
  [ProjectPhaseEvaluateStatus.doing]: '进行中',
  [ProjectPhaseEvaluateStatus.done]: '已结束',
}

// 项目阶段评价状态
export const ProjectPhaseEvaluateStatusColor = {
  [ProjectPhaseEvaluateStatus.unStart]: '#86909C',
  [ProjectPhaseEvaluateStatus.doing]: '#3593FF',
  [ProjectPhaseEvaluateStatus.done]: '#00B42A',
}

// 项目状态颜色Map
export const projectTaskStatusColor = {
  0: '#3593FF',
  1: '#6B6AFF',
  2: '#FF7B50',
  3: '#00B42A',
  4: '#33BFEB',
  10: '#F53F3F',
  99: '#C9CDD4',
}

// 项目操作类型：1,创建任务,2,指派,3,创建任务并指派,4,编辑任务,5,编辑任务并指派,6,开始任务,7,拒绝任务,8,记录任务,9,完成任务,10,打回任务,11,通过任务,12,删除任务
export const projectTaskActionTypeList = ['创建任务', '指派', '创建任务并指派给', '编辑任务', '编辑任务并指派给', '开始任务', '拒绝任务', '记录任务', '完成任务', '打回任务', '通过任务', '删除任务']

// 项目等级，一二三级
export const projectTypeLevel = [
  {
    id: 1,
    label: '一级项目',
  },
  {
    id: 2,
    label: '二级项目',
  },
  {
    id: 3,
    label: '三级项目',
  },
]

// 项目类型（1、课程项目；2、竞赛项目；3、研发项目）
export const projectType = [
  {
    id: 1,
    label: '课程项目',
  },
  {
    id: 2,
    label: '竞赛项目',
    disabled: true,
  },
  {
    id: 3,
    label: '研发项目',
    disabled: true,
  },
]

// 来源（）
export const projectSource = [
  {
    id: 1,
    label: '校本资源',
  },
  {
    id: 2,
    label: '平台推荐',
  },
]

// 项目类型
export const projectChildTypeMap = {
  1: '一级',
  2: '二级',
  3: '三级',
}

// 项目类型
export const projectTypeLabel = {
  1: '一级项目',
  2: '二级项目',
  3: '三级项目',
}

// 项目状态
export const projectStatusLabel = {
  [ProjectStatusEnum.unStart]: '未开始',
  [ProjectStatusEnum.pending]: '进行中',
  [ProjectStatusEnum.finished]: '已完成',
  [ProjectStatusEnum.overdue]: '已逾期',
  [ProjectStatusEnum.notFinal]: '未定稿',
}

// 项目状态
export const projectStatusColor = {
  [ProjectStatusEnum.unStart]: '#C9CDD4',
  [ProjectStatusEnum.pending]: '#6B6AFF',
  [ProjectStatusEnum.finished]: '#00B42A',
  [ProjectStatusEnum.overdue]: '#F53F3F',
}

// 项目类型
export const projectTypeMap = {
  1: '课程项目',
  2: '竞赛项目',
  3: '研发项目',
}

// 项目子类型列表
export const projectChildTypeList = [{ id: '', label: '全部课程项目' }, { id: 1, label: '一级项目' }, { id: 2, label: '二级项目' }, { id: 3, label: '三级项目' }]

// 审核结果;1-提交 2-通过 3-驳回 4-废弃 5-新增 6-编辑 7-取消废弃 8-公开 11-私有提交 12-私有审核通过 13-私有驳回 14-私有废弃 15-私有取消废弃 纠错结果;1-提交纠错 2-提交审核 3-取消纠错 4-废弃

export const auditSubmitType = {
  1: {
    label: '提交审核',
    tips: '将该习题设为提交审核',
    val: 1,
    color: '#6B6AFF',
  },
  2: {
    label: '审核通过',
    tips: '将该习题设为通过',
    val: 2,
    color: '#00B42A',
  },
  3: {
    label: '审核驳回',
    tips: '将该习题设为驳回',
    val: 3,
    color: '#F53F3F',
  },
  4: {
    label: '习题废弃',
    tips: '将该习题设为废弃',
    val: 4,
    color: '#F53F3F',
  },
  5: {
    label: '新增习题',
    tips: '新增习题',
    val: 5,
    color: '#F53F3F',
  },
  6: {
    label: '编辑习题',
    tips: '编辑习题',
    val: 6,
    color: '#6B6AFF',
  },
  7: {
    label: '取消废弃',
    tips: '将该习题设为取消废弃',
    val: 7,
    color: '#F53F3F',
  },
  8: {
    label: '公开习题',
    tips: '将该习题设为公开',
    val: 8,
    color: '#F53F3F',
  },
  11: {
    label: '私有提交',
    tips: '将该习题设为提交审核',
    val: 11,
    color: '#6B6AFF',
  },
  12: {
    label: '私有习题审核通过',
    tips: '将该习题设为私有审核通过',
    val: 12,
    color: '#00B42A',
  },
  13: {
    label: '私有驳回',
    tips: '将该习题设为私有驳回',
    val: 13,
    color: '#F53F3F',
  },
  14: {
    label: '私有废弃',
    tips: '将该习题设为私有废弃',
    val: 14,
    color: '#F53F3F',
  },
  15: {
    label: '私有取消废弃',
    tips: '将该习题设为私有取消废弃',
    val: 15,
    color: '#F53F3F',
  },
} as any

// 纠错结果;1-提交纠错 2-提交审核 3-取消纠错 4-废弃
export const auditErrorType = {
  1: {
    label: '习题纠错',
    tips: '将该习题设为纠错',
    val: 1,
    color: '#FF7D00',
  },
  2: {
    label: '提交审核',
    tips: '将该习题设为提交审核',
    val: 2,
    color: '#6B6AFF',
  },
  3: {
    label: '取消纠错',
    tips: '将该习题设为取消纠错',
    val: 3,
    color: '#6B6AFF',
  },
  4: {
    label: '习题废弃',
    tips: '将该习题设为废弃',
    val: 4,
    color: '#F53F3F',
  },
} as any

// 错误类型
export const auditErrorTypeList = [
  {
    label: '题干错误',
    value: 1,
  },
  {
    label: '答案错误',
    value: 2,
  },
  {
    label: '知识点错误',
    value: 3,
  },
  {
    label: '解析错误',
    value: 4,
  },
  {
    label: '其他错误',
    value: 5,
  },
  // 只有驳回有
  {
    label: '重复习题',
    value: 6,
  },
] as any

export const evalPhaseMap = {
  [SummaryMetricsEnum.normalGrade]: '平时成绩',
  [SummaryMetricsEnum.defense]: '总结答辩',
  [SummaryMetricsEnum.demonstration]: '总结演示',
  [SummaryMetricsEnum.report]: '总结报告',
}

export const evalMethodTypeMap = {
  [SummaryMetricsEnum.normalGrade]: [{ title: '教师评价', fieldName: 'teachWeight' }, { title: '小组互评', fieldName: 'studentWeight' }],
  [SummaryMetricsEnum.report]: [{ title: '教师评价', fieldName: 'teachWeight' }],
}

export const evalMethodMap = {
  1: [{ title: '教师评分', fieldName: 'teachWeight' }],
  2: [{ title: '教师评分', fieldName: 'teachWeight' }, { title: '小组互评', fieldName: 'studentWeight' }],
}

// ILO目标程度
export const ILOLevelList = [
  {
    value: 1,
    label: 'L1：记忆',
    code: 'L1',
  },
  {
    value: 2,
    label: 'L2：掌握',
    code: 'L2',
  },
  {
    value: 3,
    label: 'L3：应用',
    code: 'L3',
  },
  {
    value: 4,
    label: 'L4：分析',
    code: 'L4',
  },
  {
    value: 5,
    label: 'L5：评价',
    code: 'L5',
  },
  {
    value: 6,
    label: 'L6：创造',
    code: 'L6',
  },
]

// ILO目标程度
export const ILOLevelMap = {
  1: 'L1：记忆',
  2: 'L2：掌握',
  3: 'L3：应用',
  4: 'L4：分析',
  5: 'L5：评价',
  6: 'L6：创造',
}

// 项目阶段
export const projectStageMap = {
  1: '第一阶段',
  2: '第二阶段',
  3: '第三阶段',
  4: '第四阶段',
  5: '第五阶段',
  6: '第六阶段',
  7: '第七阶段',
  8: '第八阶段',
  9: '第九阶段',
  10: '第十阶段',
}
// 题目来源
// 学习类型;1:自主练习;2:专项训练;3:知识点阶段测试;4:课堂阶段测试;5:错题复习;6:举一反三;7:课后作业;8:课后考试;9:课堂随堂小测;10:习题库练习;11:我录入的题;12:我收藏的题;
export const exerciseSource = {
  1: {
    label: '自主练习',
    val: 1,
  },
  2: {
    label: '专项训练',
    val: 2,
  },
  3: {
    label: '知识点阶段测试',
    val: 3,
  },
  4: {
    label: '课堂阶段测试',
    val: 4,
  },
  5: {
    label: '错题复习',
    val: 5,
  },
  6: {
    label: '举一反三',
    val: 6,
  },
  7: {
    label: '课后作业',
    val: 7,
  },
  8: {
    label: '课后考试',
    val: 8,
  },
  9: {
    label: '随堂小测',
    val: 9,
  },
  10: {
    label: '习题库练习',
    val: 10,
  },
  11: {
    label: '我录入的题',
    val: 11,
  },
  12: {
    label: '我收藏的题',
    val: 12,
  },
}

// 随机封面图list
export const coverBgList = [
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/course_cover_bg1.jpg',
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/course_cover_bg2.jpg',
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/course_cover_bg3.jpg',
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/course_cover_bg4.jpg',
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/course_cover_bg5.jpg',
]

// 创作贡献榜奖牌
export const contributionList = [
  {
    name: '金牌',
    src: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/gold_medal.png',
  },
  {
    name: '银牌',
    src: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/silver_medal.png',
  },
  {
    name: '铜牌',
    src: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/bronze_medal.png',
  },
]

// 创作贡献榜-用户默认头像
export const avatarUrlList = [
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/student_avatar.png',
  'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/teacher_avatar.png',
]

export const fileTypeMap = {
  1: '视频',
  2: '图片',
  3: '文本',
  4: '音频',
  5: '办公文件',
  0: '其他',
}
// 习题状态
// 题型select
export const ExerciseStatusList = {
  [ExerciseStatus.pending]: '拆解中',
  [ExerciseStatus.finished]: '已完成',
}

// 难度select
export const ExerciseDifficultyList = {
  [ExerciseDifficulty.easy]: '容易',
  [ExerciseDifficulty.easier]: '较易',
  [ExerciseDifficulty.medium]: '适中',
  [ExerciseDifficulty.hard]: '较难',
  [ExerciseDifficulty.difficult]: '困难',
}

export const ExerciseDifficultyNumList = {
  [ExerciseDifficulty.easy]: '0.9',
  [ExerciseDifficulty.easier]: '0.7',
  [ExerciseDifficulty.medium]: '0.5',
  [ExerciseDifficulty.hard]: '0.3',
  [ExerciseDifficulty.difficult]: '0.1',
}

export type DiffTypeNum = 1 | 2 | 3 | 4 | 5

export const ProjectWayList = {
  [ExamineProjectList.work]: '作业',
  [ExamineProjectList.experiment]: '实验',
  [ExamineProjectList.class]: '课堂',
  [ExamineProjectList.exam]: '考试',
}

export const SummaryMetricsList = {
  [SummaryMetricsEnum.normalGrade]: '平时成绩',
  [SummaryMetricsEnum.defense]: '总结答辩',
  [SummaryMetricsEnum.demonstration]: '总结演示',
  [SummaryMetricsEnum.report]: '总结报告',
}

export type ProjectWayNum = 1 | 2 | 3 | 4

export const renderTypeMap = {
  1: '大纲反馈-大纲分享',
  2: '大纲反馈-内容新增',
  3: '大纲反馈-内容纠错',
  4: '大纲反馈-其他',
  5: '知识体系-知识点新增',
  6: '知识体系-知识点纠错',
  7: '知识体系-其他',
  8: '知识体系-能力点新增',
  9: '知识体系-能力点纠错',
  10: '知识体系-其他',
}
// CREATE = 1, // (1, "创建任务"),
//   DESIGNATE = 2, // (2, "指派任务"),
//   CREATEDESIGNATE = 3, // (3, "创建并指派任务"),
//   EDIT = 4, // (4, "编辑任务"),
//   EDITDESIGNATE = 5, // (5, "编辑并指派任务"),
//   START = 6, // (6, "开始任务"),
//   REJECT = 7, // (7, "拒绝任务"),
//   RECORD = 8, // (8, "记录任务"),
//   FINISH = 9, // (9, "完成任务"),
//   REPULSE = 10, // (10, "打回任务"),
//   PASS = 11, // (11,"通过任务"),
//   REMOVE = 12, // (12,"删除任务"),

// 项目任务动态
export const ProjectTaskDynamicStatusMap = {
  [ProjectTaskDynamicStatus.CREATE]: '创建任务',
  [ProjectTaskDynamicStatus.DESIGNATE]: '指派任务',
  [ProjectTaskDynamicStatus.CREATEDESIGNATE]: '创建并指派任务',
  [ProjectTaskDynamicStatus.EDIT]: '编辑任务',
  [ProjectTaskDynamicStatus.EDITDESIGNATE]: '编辑并指派任务',
  [ProjectTaskDynamicStatus.START]: '开始任务',
  [ProjectTaskDynamicStatus.REJECT]: '拒绝任务',
  [ProjectTaskDynamicStatus.RECORD]: '记录任务',
  [ProjectTaskDynamicStatus.FINISH]: '完成任务',
  [ProjectTaskDynamicStatus.REPULSE]: '打回任务',
  [ProjectTaskDynamicStatus.PASS]: '通过任务',
  [ProjectTaskDynamicStatus.REMOVE]: '删除任务',
  [ProjectTaskDynamicStatus.AUTOEND]: '自动结束',
}

// 课程图片
export const CourseImageMap = {
  classChecked: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/class-checked.png',
  classUnCheck: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/class-uncheck.png',
  workLibraryCourse: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/work-library-course.png',
  practice: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/practice.png',
  practiceEmpty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/practice-empty.png',
  practiceEmptyH108: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/practice-empty-h108.png',
  scheduleEmpty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/schedule_empty.png',
  commonEmpty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/common-empty.png',
  workEmpty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/work-emoty.png',
  todoEmpty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/todo-empty.png',
  courseEmpty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/app/teaching/empty.png',
  syllabus: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/syllabus.png',
  knowledge: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/knowledge.png',
  class: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/class.png',
  task: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/task.png',
  random_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/random_icon.png',
  create_random_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/create_random_icon.png',
  task_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/task_icon.png',
  task_study_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/task_study_icon.png',
  evaluate_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/evaluate_icon.png',
  lesson_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/lesson_icon.png',
  project_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/project_icon.png',
  course_lesson_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/course_lesson_icon.png',
  exam_icon: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/exam_icon.png',
  lesson_empty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/lesson_empty.png',
  class_empty: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/icon_teaching_space/class_empty.png',
}

export const StudyStatus = {
  1: '待开始',
  2: '学习中',
  3: '已结束',
  4: '上课中',
}

export const StudyStatusColor = {
  1: '#6B6AFF',
  2: '#3593FF',
  3: '#C9CDD4',
  4: '#FF7B50',
}

export const fileDirectoryTypeText = {
  [fileDirectoryTypeEnum.projectFile]: '项目资料',
  [fileDirectoryTypeEnum.groupFile]: '小组资料',
}
export const examTypeText = {
  [examTypeEnum.stage]: '阶段考试',
  [examTypeEnum.midterm]: '期中考试',
  [examTypeEnum.final]: '期末考试',
}

// 域名集合
export const _HOSTMAP = {
  local: {
    teach: 'http://localhost:3010',
    study: 'http://localhost:3008',
    admin: 'http://localhost:3011',
    website: 'http://localhost:3020',
  },
  dev: {
    teach: 'https://dev-teach.metaobe.com',
    study: 'https://dev-study.metaobe.com:9443',
    admin: 'https://dev-admin.metaobe.com',
    website: 'https://dev.metaobe.com',
  },
  test: {
    teach: 'https://test-teach.metaobe.com:9443',
    study: 'https://test-study.metaobe.com:9443',
    admin: 'https://test-admin.metaobe.com:9443',
    website: 'https://test.metaobe.com:9443',
  },
  prod: {
    teach: 'https://teach.metaobe.com',
    study: 'https://study.metaobe.com',
    admin: 'https://admin.metaobe.com',
    website: 'https://www.metaobe.com',
  },
}

// 预约状态名称
export const BookingStatusNameMap = {
  [BookingStatus.Audit]: '待审核',
  [BookingStatus.Success]: '预约成功',
  [BookingStatus.Rescinded]: '已撤销',
  [BookingStatus.Pause]: '暂停使用',
  [BookingStatus.AuditFail]: '审核不通过',
  [BookingStatus.Expired]: '已过期',
}
