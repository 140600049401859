/*
 * @Author: 宋绍华
 * @Date: 2024-01-03 09:10:21
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-01-03 09:12:26
 * @FilePath: \dcy-web\packages\middleware\auth.ts
 * @Description:
 */
export default async function (to) {
  const { path } = to
  const whiteList = ['/login', '/register', '/forgot-password', '/401', '/404']
  if (whiteList.includes(path))
    return
  // const userStore = useUserStore()
  // await userStore.info()
  const userInfo = localStorage.getItem('menuInfo') ? JSON.parse(localStorage.getItem('menuInfo')) : {}
  // eslint-disable-next-line unused-imports/no-unused-vars
  const hasAuth = userInfo.menuPermission?.includes(path)
  // TODO: 暂时去掉校验权限
  // if (!hasAuth && to.path !== '/')
  //   return navigateTo('/401')
}
