/*
 * @Author: 熊志伟
 * @Date: 2023-12-14 14:44:57
 * @LastEditors: 熊志伟
 * @LastEditTime: 2024-03-11 16:44:37
 * @FilePath: \dcy-web\apps\admin\middleware\auth.global.ts
 * @Description: 路由中间件
 */
import authMiddleware from '@dcyjs/middleware/auth'

export default defineNuxtRouteMiddleware((to) => {
  // eslint-disable-next-line node/prefer-global/process
  if (process.server)
    return
  authMiddleware(to)
})
