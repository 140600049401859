/*
 * @Author: 宋绍华
 * @Date: 2024-08-09 10:28:57
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-08-21 17:10:38
 * @FilePath: \dcy-web\packages\utils\formate\formateBetweenTime.ts
 * @Description:
 */
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export const formateBetweenTime = dayjs
