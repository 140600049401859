/*
 * @Author: 熊志伟
 * @Date: 2023-12-11 13:58:35
 * @LastEditors: 娄松 
 * @LastEditTime: 2024-10-11 16:16:20
 * @FilePath: \dcy-web\apps\learning-space\services\user.ts
 * @Description: 登录相关接口
 */
import { request } from '@dcyjs/network'
import type { AxiosRequestHeaders } from 'axios'
import type { BaseSuccessResponese, ForgetPasswordQuery, AccountDetailResponse, ResourceDetailResponse,LoginResponse, SendPhoneCodeInfo, UpdatePasswordRequest, UserLoginInfo, UserRegisterInfo } from '@/services/types/user'

export interface LoginHeaders extends AxiosRequestHeaders {
  addPid: string
}

export function login(data: UserLoginInfo, headers: LoginHeaders) {
  return request<LoginResponse>('/login', data, 'POST', false, headers)
}

export function getPubKey() {
  return request<string>('/passwordPubKey', {}, 'GET')
}

export function getUserInfo() {
  return request<ResourceDetailResponse>('/resource/detail', {}, 'GET')
}

export function register(data: UserRegisterInfo) {
  return request<BaseSuccessResponese>('/register', data)
}


// 忘记密码验证码
export function sendForgetPasswordVerifyCode(data: SendPhoneCodeInfo) {
  return request<BaseSuccessResponese>('/resource/sendForgetPasswordVerifyCode', data)
}

export function forgetPassword(data: ForgetPasswordQuery) {
  return request<BaseSuccessResponese>('/resource/forgetPassword', data)
}

export function updatePassword(data: UpdatePasswordRequest) {
  return request<BaseSuccessResponese>('/resource/updatePassword', data)
}


export function getUserDetail(id: number) {
  return request<AccountDetailResponse>(`/resource/account/detail/${id}`, {}, 'GET')
}

// 修改密码
/**
 * UpdatePasswordReqOpenDTO
 */
export interface UpdatePasswordReqOpenDTO {
  /**
   * 应用ID
   */
  appId?: number
  /**
   * 确认密码
   */
  confirmPassword?: string
  /**
   * 身份编码;student:学生; teacher:教师; manage:管理;
   */
  identityCode?: string
  /**
   * 新密码
   */
  newPassword?: string
  /**
   * 密码
   */
  password?: string
  /**
   * 用户名
   */
  username?: string
  [property: string]: any
}
export function updatePasswordData(data: UpdatePasswordReqOpenDTO) {
  return request<string>('/resource/updatePassword', data)
}

// 修改个人信息
export function updateUserInfo(data: any) {
  return request<BaseSuccessResponese>('/resource/updateAccountBaseInfo', data)
}

// 修改个人信息
export function updateUserMobile(data: any) {
  return request<BaseSuccessResponese>('/mobileChange', data)
}

// 发送手机号换绑验证码
export function sendMobileChangeVerifyCode(data: SendPhoneCodeInfo) {
  return request<BaseSuccessResponese>('/sendMobileChangeVerifyCode', data)
}

// 修改管理员验证码
export function sendAdminChangeVerifyCode(data: SendPhoneCodeInfo) {
  return request<BaseSuccessResponese>('/resource/school/admin/phoneVerifyCode', data)
}


// 管理员变更
export function updateAdmin(data: SendPhoneCodeInfo) {
  return request<BaseSuccessResponese>('/resource/school/admin/update', data)
}
