/*
 * @Author: 宋绍华
 * @Date: 2024-02-03 17:24:11
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-02-03 17:25:29
 * @FilePath: \dcy-web\apps\learning-space\plugins\iconfont.ts
 * @Description:
 */
import type { NuxtApp } from 'nuxt/app'
import { IconFont } from '@dcyjs/materials'

export default function (nuxtApp: any) {
  (nuxtApp as NuxtApp).vueApp.component('IconFont', IconFont)
}
