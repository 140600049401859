/*
 * @Author: 熊志伟
 * @Date: 2023-12-11 09:13:07
 * @LastEditors: suk.bear suk.bear.suwu@gmail.com
 * @LastEditTime: 2024-01-23 18:47:20
 * @FilePath: \dcy-web\apps\learning-space\stores\modules\user\index.ts
 * @Description: user modules
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import { Message } from '@arco-design/web-vue'
import { clearAllCache, setToken } from '@dcyjs/utils/auth'
import { removeRouteListener } from '@dcyjs/utils/route-listener'
import { filterData, flatData, rsaEncrypt } from '@dcyjs/utils'
import useAppStore from '../app'
import type { ResourceDetailResponse, UserLoginInfo } from '@/services/types/user'
import { getPubKey } from '@/services'

import {
  getUserInfo,
  login as userLogin,
} from '@/services/user'

const useUserStore = defineStore('user', {
  state: (): ResourceDetailResponse => ({
    /**
     * 账号id
     */
    accountId: 0,
    /**
     * 账号名称
     */
    accountName: '',
    /**
     * 应用ID
     */
    appId: 0,
    /**
     * 菜单
     */
    menuList: [],
    // 按钮一维数组集合
    permission: [],
    // 路由一维数组集合
    menuPermission: [],
    /**
     * 手机号
     */
    phone: '',
    /**
     * 平台id
     */
    platformId: 0,
    /**
     * 角色集合
     */
    roleList: [],
    identityCode: '',
  }),

  persist: {
    storage: persistedState.localStorage,
    paths: ['accountId', 'phone', 'accountName'],
  },
  getters: {
    userInfo(state: ResourceDetailResponse): ResourceDetailResponse {
      return { ...state }
    },
    otherNames(state: ResourceDetailResponse) {
      return state.previousNames.filter((name: string) => state.savedName !== name)
    },
  },

  actions: {
    setNewName(name: string) {
      if (this.savedName)
        this.previousNames.push(this.savedName)
      this.savedName = name
    },
    switchRoles() {
      return new Promise((resolve) => {
        this.role = this.role === 'teacher' ? 'student' : 'teacher'
        resolve(this.role)
      })
    },
    setExerciseInfo(res: any) {
      this.exerciseInfo = res
    },

    // Set user's information
    setInfo(partial: Partial<ResourceDetailResponse>) {
      this.$patch(partial)
    },

    // Reset user's information
    resetInfo() {
      this.$reset()
    },

    // Get user's information
    async info() {
      const userInfo = await getUserInfo()

      // if (process.env.NODE_ENV === 'development') {
      //   const { data } = await useFetch('/menu')
      //   userInfo.menuList = data.value || []
      // }
      // 从按钮、菜单集合tree筛选出菜单数据
      const menuList = filterData(JSON.parse(JSON.stringify(userInfo.menuList)), 'type', 1)
      const permission = flatData(JSON.parse(JSON.stringify(userInfo.menuList)))?.map(item => item.code)
      // 拍平菜单集合
      const menuPermission = flatData(JSON.parse(JSON.stringify(userInfo.menuList)))?.map(item => item.pathUrl)
      localStorage.setItem('menuInfo', JSON.stringify({ menuList, permission, menuPermission }))
      this.setInfo({ ...userInfo, menuList, permission, menuPermission })
    },

    // Login
    async login(loginForm: UserLoginInfo, logincCallback: () => void) {
      try {
        const pubKey = await getPubKey()
        const { password } = loginForm
        const params = {
          ...loginForm,
          password: await rsaEncrypt(pubKey, password) || '',
        }
        const { accessToken } = await userLogin(params, { appId: loginForm.appId })
        setToken(accessToken)
        await this.info()
        Message.success('登录成功')
        logincCallback()
      }
      catch (err) {
        // clearToken()
        clearAllCache()
        throw err
      }
    },
    logoutCallBack() {
      const appStore = useAppStore()
      this.resetInfo()
      // clearToken()
      clearAllCache()
      removeRouteListener()
      appStore.clearServerMenu()
    },
    // Logout
    async logout(callback: Function) {
      this.logoutCallBack()
      callback()
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore as any, import.meta.hot))

export default useUserStore
