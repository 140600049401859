<!--
 * @Author: 宋绍华
 * @Date: 2024-02-19 17:43:00
 * @LastEditors: luosi 1297957337@qq.com
 * @LastEditTime: 2024-02-20 16:25:44
 * @FilePath: \dcy-web\packages\materials\components\IconFont.vue
 * @Description:
-->
<script setup lang="ts">
import { Icon } from '@arco-design/web-vue'

defineProps({
  type: {
    type: String,
    default() {
      return ''
    },
  },
  size: {
    type: String,
    default() {
      return ''
    },
  },
})

const IconFont = Icon.addFromIconFontCn({ src: 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/files/!iconfont.js?a=1' })
</script>

<template>
  <IconFont :type="type" :size="size" />
</template>
