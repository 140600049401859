import revive_payload_client_Hf75hwkYxC from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.4_eslint@8.57.1_less@4.2.0_rollup@2.79.2_typescript@5.6.3_vite@4.5.5_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pXsYkOvKBE from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.4_eslint@8.57.1_less@4.2.0_rollup@2.79.2_typescript@5.6.3_vite@4.5.5_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Riy88TMfaM from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.4_eslint@8.57.1_less@4.2.0_rollup@2.79.2_typescript@5.6.3_vite@4.5.5_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_3RbpIqDClK from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/workspace/dcy-web_F0Xe/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_yLIU2kxZCo from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.4_eslint@8.57.1_less@4.2.0_rollup@2.79.2_typescript@5.6.3_vite@4.5.5_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_5MWjj1e3l6 from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_rollup@3.29.5_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_UNd2vWqLGE from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_rollup@3.29.5_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_paZu7tWwWb from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/root/workspace/dcy-web_F0Xe/apps/admin/.nuxt/unocss.mjs";
import chunk_reload_client_wkaAr51zC4 from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.4_eslint@8.57.1_less@4.2.0_rollup@2.79.2_typescript@5.6.3_vite@4.5.5_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_zywQL3rGKk from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.4_eslint@8.57.1_less@4.2.0_rollup@2.79.2_typescript@5.6.3_vite@4.5.5_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_f0qRQwIl9e from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_rollup@3.29.5/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import arco_design_icon_mmwhkaCiTW from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/arco-design-icon.ts";
import iconfont_MoxupmSx7j from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/iconfont.ts";
import permission_RYX9gEepuf from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/permission.ts";
import quill_editor_client_tupKYLQr8r from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/quill-editor.client.ts";
import vue_office_pdf_client_1QeVzdI8HH from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/vue-office-pdf.client.ts";
export default [
  revive_payload_client_Hf75hwkYxC,
  unhead_pXsYkOvKBE,
  router_Riy88TMfaM,
  plugin_vue3_3RbpIqDClK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yLIU2kxZCo,
  composition_5MWjj1e3l6,
  i18n_UNd2vWqLGE,
  plugin_client_paZu7tWwWb,
  unocss_MzCDxu9LMj,
  chunk_reload_client_wkaAr51zC4,
  check_outdated_build_client_zywQL3rGKk,
  plugin_f0qRQwIl9e,
  arco_design_icon_mmwhkaCiTW,
  iconfont_MoxupmSx7j,
  permission_RYX9gEepuf,
  quill_editor_client_tupKYLQr8r,
  vue_office_pdf_client_1QeVzdI8HH
]