/*
 * @Author: 熊志伟
 * @Date: 2023-12-08 15:39:20
 * @LastEditors: 熊志伟
 * @LastEditTime: 2023-12-13 14:48:42
 * @FilePath: \dcy-web\packages\utils\jsencrypt.ts
 * @Description: rsa加解密
 */
// import JSEncrypt from 'jsencrypt'
/**
 * 加密
 * @param pubKey 公钥
 * @param msg 待加密字符串
 */
export async function rsaEncrypt(pubKey: string, msg: string) {
  // 由于jsencrypt直接import引入会报错window is undefined，而且高版本打包会报错，作为插件引入也会报错，故采用动态引入
  const JSEncrypt = (await import('jsencrypt')).default
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(pubKey)
  return encryptor.encrypt(msg)
}

/**
 * 解密
 * @param priKey 私钥
 * @param encryptedText 待解密字符串
 */
export async function rsaDecrypt(priKey: string, encryptedText: string) {
  const JSEncrypt = (await import('jsencrypt')).default
  const decrypt = new JSEncrypt()
  decrypt.setPrivateKey(priKey)// 设置秘钥
  return decrypt.decrypt(encryptedText)// 解密之前拿公钥加密的内容
}
