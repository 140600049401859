/*
 * @Author: 宋绍华
 * @Date: 2024-08-14 14:04:21
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-08-14 14:07:23
 * @FilePath: \dcy-web\packages\utils\formate\formateIsSameOrAfter.ts
 * @Description:
 */
import cn from 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfter)
dayjs.locale(cn)

export const formateIsSameOrAfter = dayjs
