/*
 * @Author: 熊志伟
 * @Date: 2023-12-11 09:13:07
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-05-30 09:57:32
 * @FilePath: \dcy-web2\apps\admin\stores\modules\app\index.ts
 * @Description: app modules
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { RouteRecordNormalized } from 'vue-router'
import type { AppState } from './types'
import defaultSettings from '@/config/settings'

const useAppStore = defineStore('app', {
  state: (): AppState => ({ ...defaultSettings }),

  getters: {
    appCurrentSetting(state: AppState): AppState {
      return { ...state }
    },
    appDevice(state: AppState) {
      return state.device
    },
    appAsyncMenus(state: AppState): RouteRecordNormalized[] {
      return state.serverMenu as unknown as RouteRecordNormalized[]
    },
  },

  actions: {
    // Update app settings
    updateSettings(partial: Partial<AppState>) {
      // @ts-expect-error-next-line
      this.$patch(partial)
    },

    // Change theme color
    toggleTheme(dark: boolean) {
      if (dark) {
        this.theme = 'dark'
        document.body.setAttribute('arco-theme', 'dark')
      }
      else {
        this.theme = 'light'
        document.body.removeAttribute('arco-theme')
      }
    },
    toggleDevice(device: string) {
      this.device = device
    },
    toggleMenu(value: boolean) {
      this.hideMenu = value
    },
    toggleSearchBar(value: boolean) {
      this.searchBar = value
    },
    // async fetchServerMenuConfig() {
    //   let notifyInstance: NotificationReturn | null = null
    //   try {
    //     notifyInstance = Notification.info({
    //       id: 'menuNotice', // Keep the instance id the same
    //       content: 'loading',
    //       closable: true,
    //     })
    //     const { data } = await getMenuList()
    //     this.serverMenu = data
    //     notifyInstance = Notification.success({
    //       id: 'menuNotice',
    //       content: 'success',
    //       closable: true,
    //     })
    //   }
    //   catch (error) {
    //     notifyInstance = Notification.error({
    //       id: 'menuNotice',
    //       content: 'error',
    //       closable: true,
    //     })
    //   }
    // },
    clearServerMenu() {
      this.serverMenu = []
    },
  },
  persist: {
    storage: persistedState.localStorage,
    paths: ['menuKey'],
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAppStore as any, import.meta.hot))

export default useAppStore
